import React  from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet"; 
import { MailIcon, PhoneIcon, SunIcon } from "@heroicons/react/solid";
import ContactForm from "../components/form/ContactForm";
import { StaticImage } from "gatsby-plugin-image";
import GMap from "../components/Map";
import {default as Zahrady} from "../images/zahrady.svg";
import { graphql } from "gatsby";
import {AiOutlineFilePdf} from "react-icons/ai"
import downloadFile from '../images/standardy.pdf' 

 
export const query = graphql`
query TechnickyPopis {
    scomos {
        pageFindOneByUrl(url:"technicky-popis/") {
            content
        }
      }
    }
`

const TechnickyPopisPage = ({location, data}) => {
   
  const content = data?.scomos?.pageFindOneByUrl?.content

  return (
  <>
       <Helmet>
      <meta charSet="utf-8" />
      <title>Technický popis | Domy Trio</title> 
    </Helmet>
      <Layout location={location}> 
   

      
<main >
    <div className="mx-auto w-full   relative z-30 ">
       <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 z-auto  backdrop-filter  bg-white bg-opacity-90 relative shadow-md border border-gray-200 ">    
             <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                <polygon points="0,0 300,0 300,300" class="triangle" fill="currentColor" />
                <SunIcon width="50" x="160" y="-70"  fill="#ffffff" />
            </svg>
            <div className="relative">
           <div className="">
               <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">Technický popis</h1> 
           </div>
           <div> 
               <a href={downloadFile} target="_blank" className="px-3 py-3 my-3 bg-gray-100 border border-yellow-400 items-center inline-flex space-x-6 rounded-md hover:bg-gray-200">
                <AiOutlineFilePdf className="w-8 h-8 text-red-600" />
                <div className="grid">
                    <span>Standardy interiéru</span>
                    <span className="text-xs">(PDF ke stažení, velikost 29 MB)</span></div>
               </a>
            </div>

                   
        </div>
        <div dangerouslySetInnerHTML={{__html: content}} className="mt-6 prose prose-yellow prose-sm max-w-6xl   text-gray-600 mx-auto pb-10"> 
                        </div>
    </div>

    </div>

  </main>
    <div >
        
  </div>
    </Layout>
  </>
  )
}

export default TechnickyPopisPage
 